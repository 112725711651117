import React, { useState, useEffect, useRef } from 'react';
import StateStore from "../StateStore";
import {
	Box, CircularProgress, LinearProgress
} from '@mui/material';
import useWindowDimensions from "../Hooks/UseWindowDimensions";

export const ProgressCircle = (props) => {
	const stateStore = new StateStore("Error");
	const [, setDisplay] = useState();
	const ref = useRef([]);
	const UpdateComponent = (stateValue) => {
		setTimeout(() => {
			let forComp = stateValue.for;
			let disp = stateValue.display;
			let displayTemp = [...ref.current];
			if (disp === true) {
				//add to stack
				if (!displayTemp.includes(forComp)) {
					displayTemp.push(forComp);
				}
				if (displayTemp.length === 1) {
					setDisplay([...displayTemp]);
				}
			}
			else {
				//remove from stack
				if (forComp === "pageclear") {
					displayTemp = []
					setDisplay([...displayTemp]);
				}
				else {
					if (displayTemp.includes(forComp)) {
						displayTemp.splice(displayTemp.indexOf(forComp), 1);
					}
					if (displayTemp.length === 0) {
						setDisplay([...displayTemp]);
					}
				}
			}
			ref.current = [...displayTemp]
			
			
		}, 0);
	};

	useEffect(() => {
		stateStore.Subscribe("progress", "progress", UpdateComponent);

		return () => {
			stateStore.UnSubscribe("progress", "progress");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const { height } = useWindowDimensions();
	
	if (ref.current.length === 0)
		return <></>;
	return (
		
		<Box sx={{
			position: 'absolute', top: 65, left: 0, zIndex: 10000, width: '100%', height: height - 125,
			alignItems: 'center', backgroundColor: 'modelBgColor',
			justifyContent: 'center'
		}}>{props.type === "bar" &&
				<LinearProgress mt="100" />}
			{props.type === "circle" &&
				<Box sx={{
					display: 'flex', alignItems: 'center', height: '100%',
					justifyContent: 'center'
				}}>

					<CircularProgress m="50" />

				</Box>}
		</Box>
	)

}