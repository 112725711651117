import React, {  useEffect,  useState } from 'react';
import {
	Box,  Alert
} from '@mui/material';
import {  useNavigate} from "react-router-dom";
import StateStore from "../StateStore"; 
import { Store } from "../Store";

export const AlertMessage = (props) => {
	const stateStore = new StateStore("Error");
	const appStore = new Store("app");
	const [display, setDisplay] = useState(false);
	const navigate = useNavigate();

	const response = appStore.Get("Error");

	const UpdateComponent = (value) => {
		let timeout = 4000;
		if (response?.severity === "error") {
			timeout = 8000;
		}
		if (value === "error" || value === "noaccess" || value === "notfound") {
			navigate("/" + value);
		}
		else {
			setDisplay(true);
			setTimeout(() => { setDisplay(false); appStore.Set("Error", undefined); }, timeout)
		}
		stateStore.Set("progress", {
			display: false, for: "pageclear" });
	};

	useEffect(() => {
		stateStore.Subscribe("alert", "alertmessage", UpdateComponent);

		return () => {
			stateStore.UnSubscribe("alert", "alertmessage");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	
	if (display === false)
		return "";
	return (
		<Box sx={{
			position: 'absolute', top: 70, left: 0, zIndex: 9999, width: '100%', height: 'auto'
		}}>
			<Box sx={{
				 display:'flex',
				justifyContent: 'end',
				zIndex: 9999,
			}}>
				<Alert severity={response?.severity} sx={{ minWidth: 500 }}>{response?.message}</Alert>
				</Box>
		</Box>
	)

}