
// write your own method and assign the method name in config
export const AppCustomEvents = {
	onAppLoad: async function () {
		//check this. object
		//console.log("custom function onAppLoad")
		// not implemented yet

		return true;
	},

	// onPageLoad will be executed before execution of dataSource URL in config
	onCustomBeforePageLoad: async function () {
		//console.log("custom function onCustomBeforePageLoad:" + this.name)
		const urlParams = this?.urlParams;
		const customerid = urlParams["customerid"];
		this.appStore.Set("redirectLocation", this.location);
		if (customerid && customerid === ":customerid") {
			let url = "";
			//console.log("customer id is missing")
			// if the customer id is available in datastore, update the url and redirect
			let customer = this.appStore.Get("selectedCustomer");
			if (customer && customer.CustomerNumber) {
				url = this.location.replace(":customerid", customer?.CustomerNumber);
				setTimeout(() => this.navigate(url, { replace: true }), 10);
				return false;
			}
			else {
				customer = localStorage.getItem("preferedCustomer");
				customer = JSON.parse(customer);
				// else navigate to customer selection
				if (customer && customer.CustomerNumber) {
					url = this.location.replace(":customerid", customer?.CustomerNumber);
					setTimeout(() => this.navigate(url, { replace: true }), 10);
					return false;
				}
				else {
					setTimeout(() => this.navigate("/customer"), 10)
				}
			}
			return false;
		}
		else {
			// update in datastore
			customerid && this.appStore.Set("selectedCustomer", { "CustomerNumber": customerid })
		}
		//returns true or false
		return true;
	},
	onCustomAfterPageLoad: async function () {
		//console.log("custom function onPageAfterLoad:" + this.name)

		const urlParams = this?.urlParams;
		const customerid = urlParams["customerid"];
		const url = "/UserCustomers";
		//let getCustomers = false;

		//let customer = this.dataStore.getAppStoreValue("selectedCustomer")  
		//if (customer && customer.CustomerNumber !== customerid) {
		//	customer = JSON.parse(localStorage.getItem("preferedCustomer"));
		//	if (customer && customer.CustomerNumber === customerid) {
		//		if (customer.CustomerName === undefined)
		//			getCustomers = true;
		//	}
		//	else getCustomers = true;
		//}
		//else {
		//	if (customer.CustomerName === undefined) {
		//		getCustomers = true;
		//	}
		//      }

		//if (getCustomers) {

		//}

		let title = document.querySelector("[data-qa-element='pageHeader'] h6");
		if (title) {
			let customers = this.appStore.Get("customers");
			const appResources = this.config.dataSource?.appResources ?? { ...this.appStore.Get("appCfg.serviceCfg.appResources") };
			customers = customers ?? await this.callApi(appResources, url);
			this.appStore.Set("customers", customers);
			let customer = (customers?.filter(cust => cust.CustomerNumber === customerid) ?? [])[0];
			let customerInfo = " - " + customer?.CustomerName?.trim() ?? customer?.CustomerNumber;
			let titleText = title?.innerText.replace(customerInfo, '');
			title.innerText = titleText + customerInfo;
			customerid && this.appStore.Set("selectedCustomer", customer) && localStorage.setItem("preferedCustomer", JSON.stringify(customer));
		}
		return true;
	},

	// always returns the true / false
	// it runs before the form object updates
	onCustomComponentLoad: async function () {
		// not implemented yet
	},


	onCustomomerPageAfterLoad: async function () {
		console.log("custom function onCustomomerPageAfterLoad:" + this.name)
		let customers = this.pageStore.Get("customers.data");
		if (customers && customers?.length === 1) {
			localStorage.setItem("preferedCustomer", JSON.stringify(customers[0]));
			let url = this.appStore.Get("redirectLocation")?.replace(":customerid", customers[0]?.CustomerNumber);
			this.appStore.Clear("redirectLocation");
			if (url)
				setTimeout(() => this.navigate(url, { replace: true }), 10);
			else
				console.log("no redirect location found in datastore.")
		}
		return true;
	},

	// onBeforeChange always reutns value
	onSelectCustomer: async function (value) {
		if (value && value.CustomerNumber) {
			this.appStore.Set("selectedCustomer", value);
			localStorage.setItem("preferedCustomer", JSON.stringify(value));

			// eslint-disable-next-line
			let regexExpr = /([^\/]+)/i; // not adding 'g' modifier because we just want to return/replace first match.
			let url = this.appStore.Get("redirectLocation")?.replace(regexExpr, value?.CustomerNumber);
			url = url ?? "/"
			this.appStore.Clear("redirectLocation");
			if (url)
				setTimeout(() => this.navigate(url, { replace: true }), 10);
			else
				console.log("no redirect location found in datastore.")
		}
		return value;
	},

	onResetLocalData: async function () {
		console.log("custom function onResetLocalData:" + this.name)

		// Removing item from local storage.
		localStorage.removeItem("preferedCustomer");
		// Removing Data from Session Storage.
		sessionStorage.removeItem("sessionid");
		sessionStorage.removeItem("authzToken");
		sessionStorage.removeItem("userPermission");
		//Removing Indexed DB.
		await this.pageStore.DeleteLocalDB();

		//Redirect to Home Page.
		// No need to clear App Store & Page Store Data as next line will redirect to home page.
		// Everytime the site loads the App Store and Page Store are removed.
		setTimeout(() => window.location.replace(window.location.origin), 1000)
	},


	onGridRefresh: async function () {
		//console.log('inside onGridRefresh')
		let data = this.pageStore.Get(this.config.dataSource.dStoreName);
		this.pageStore.Set(this.config.dataSource.dStoreName, { ...data, data: undefined });
		return true;
	}


}