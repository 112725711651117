var AppState = {};
var listeners = {};
export default class StateStore {
    constructor(name) {
        this.name = name;
        this.State = AppState;
        this.listeners = listeners;
    }

    Get(key) {
        return this.State[key];
    }

    Set(key, value) {
        this.State[key] = value;
        this.Dispatch(key, value);
    }
    Subscribe(state_key, subscriber, callback) {
        if (!this.listeners[state_key]) {
            this.listeners[state_key] = {};
        }
        this.listeners[state_key][subscriber] = callback;
    }

    UnSubscribe(state_key, subscriber) {
        if (!this.listeners[state_key]) {
            this.listeners[state_key] = {};
        }
        delete this.listeners[state_key][subscriber]
        if (this.listeners[state_key] && Object.keys(this.listeners[state_key]).length === 0) {
            delete this.listeners[state_key]
        } 
       
        //this.listeners[state_key][subscriber] = undefined;
    }

    Dispatch(state_key, state_value) {
        if (this.listeners[state_key]) {
            Object.values(this.listeners[state_key]).forEach((listener) =>
                listener && listener(state_value)
            );
        }
    }
}
