import React, { useEffect, useState } from 'react';
import './index.css';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import MsalHelper from "./MsalHelper";
import { Store } from "./Store";


function AppReady() {
    
    const [appReady, setAppReady] = useState(false);
    let appCfg = {};
    const AppStore = new Store("app");
    const GetAppConfig = async () => {
        const appCfgModule = await import(/* webpackIgnore: true */ process.env.PUBLIC_URL+"/static/configs/" + process.env.REACT_APP_ENV+"/appConfig.js");
        appCfg = { ...appCfgModule.appConfig }
        AppStore.Set("appCfg", appCfg);
        setAppReady(true);
    }

    useEffect(() => {
        GetAppConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    let msalInstance = MsalHelper.getAppInstance(AppStore.Get("appCfg")?.authCfg);
    return (
    <>
        { appReady &&
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
        }
            {!appReady && <>Loading...</>}
        </>
    );
}

export default AppReady;