import { Store } from "./Store";
// F: - formula
// M: - Method
// R: - Rule
// OP - +,-,*,/
// Logical - AND, OR, NOT
// LEN: - Length
// SUM: - sum - TODO
// AVG: - average - TODO
// MIN: - minimum - TODO
// MAX: - Maximum - TODO
// PROPS: - return properties of  an object - TODO
// PROPS?: - return properties of an object which are having value - TODO
// EMPTY: -  Checks is object empty, return true / false

//Evaluate expression
export const EvalExpression = (expression, currObj) => {

    if (expression === undefined) {
        return expression;
    }
    
    switch (true) {
        case /^M:/i.test(expression): {
            return EvalMethod(expression.replace(/^M:/, ""), currObj);
        }
        case /^R:/i.test(expression): {
            return EvalFormula(expression.replace(/^R:/, ""), currObj);
        }
        case /^F:/i.test(expression): {
            return EvalFormula(expression.replace(/^F:/, ""), currObj);
        }
        default:
            return expression;
    }
};



// regex to get all groups (...)
const groupRegex = /\((?:[^()])*\)/g;
// get all operator
const opRegex = /(\+|\*|-|\/|AND|OR|==|<=|>=|<|>|!=)/g;
//const condRegex = /^(IF:)/g;
// get all agg functions
const aggRegex = /^(LEN:|EMPTY:|NOTEMPTY:|SUM:|AVG:|MIN:|MAX:)/g;
// passed string is number
//const numberRegex = /^-?\d+\.?\d*$/;
//is literal starts with ' and ends with '
const litteralRegex = /'([^']*)'/g;

const EvalFormula = (formula, currObj) => {
    
    let innerGroups = formula.match(groupRegex);

    if (innerGroups !== null) {
        let result = formula;
        innerGroups?.forEach((group) => {
            result = result.replace(
                group,
                EvalFormula(group.replace(/\(|\)/g, ""), currObj)
            );
            //console.log(result);
        });
        result = EvalFormula(result, currObj);
        return result;
    } else {
        let destructFormula = formula.split(opRegex);
        let op;
        let initialLeft = isQuoted(destructFormula[0])
            ? destructFormula[0].replace(/'/g, "").trim()
            : destructFormula[0].trim();
        //initialLeft = initialLeft.trim()
        // if no operands && starts with LEN or EMPTY or SUM or AVG or MIN or MAX
        if (destructFormula.length === 1 && isAggFun(initialLeft)) {
            initialLeft = EvalAgg(initialLeft, currObj);

            return initialLeft;
        }
        if (isAggFun(initialLeft)) {
            initialLeft = EvalAgg(initialLeft, currObj);
        } else {
            // else it is arithmetic or logical operation
            initialLeft =
                resolve(destructFormula[0].trim(), currObj) ??
                destructFormula[0].trim();
            if (destructFormula.length === 1) {
                return initialLeft;
            }
        }
        let result = destructFormula.reduce(
            (left, right) => {
                // console.log("leflt" + left);
                opRegex.lastIndex = 0;
                if (opRegex.test(right)) {
                    op = right;
                } else if (op !== null && op !== undefined) {
                  //  console.log("right" + right);
                   // console.log("left" + left);

                    if (isBoolean(left)) {
                        if (typeof left === 'string') {
                            left = left.replace(/'/g, "");
                            left = left.trim()
                        }
                        left = left.toString() === "true";
                    } else if (isNumber(left)) {
                        left = parseFloat(left);
                    } else if (isQuoted(left)) {
                        left = left.replace(/'/g, "");
                        left = ("" + left).trim() === "" ? left : ("" + left).trim();
                        // console.log(left)
                    } else {
                        left = left.replace(/\s/g, "");
                        if (isAggFun(left)) {
                            left = EvalAgg(left, currObj);
                        } else {
                            left = resolve(("" + left).trim(), currObj) ?? left;
                        }
                    }

                      if (isBoolean(right)) {
                        if (typeof right === 'string') {
                            right = right.replace(/'/g, "");
                            right = right.trim()
                        }
                        right = right.toString() === "true";
                    } else if (isNumber(right)) {
                        right = parseFloat(right);
                    } else if (isQuoted(right)) {
                        right = right.replace(/'/g, "");
                        right = ("" + right).trim() === "" ? right : ("" + right).trim();
                    } else {
                        right = right.replace(/\s/g, "");
                        if (isAggFun(right)) {
                            right = EvalAgg(right, currObj);
                        } else {
                            right = resolve(("" + right).trim(), currObj);
                        }
                    }
                    let oprends = [];
                    switch (op) {
                        case "+":
                            oprends = prepareNumberOperation(left, right);
                            left = add(oprends[0], oprends[1]);
                            break;
                        case "-":
                            oprends = prepareNumberOperation(left, right);
                            left = subtract(oprends[0], oprends[1]);
                            break;
                        case "*":
                            oprends = prepareNumberOperation(left, right);
                            left = multiply(oprends[0], oprends[1]);
                            break;
                        case "/":
                            oprends = prepareNumberOperation(left, right);
                            if (oprends[1] !== "0") left = divide(oprends[0], oprends[1]);
                            break;
                        case "AND":
                            left = and(left, right);
                            break;
                        case "OR":
                            left = or(left, right);
                            break;
                        case "==":
                            left = eq(left, right);
                            break;
                        case "!=":
                            left = neq(left, right);
                            break;
                        case "<":
                            left = less(left, right);
                            break;
                        case "<=":
                            left = lesseq(left, right);
                            break;
                        case ">":
                            left = gr(left, right);
                            break;
                        case ">=":
                            left = greq(left, right);
                            break;
                        default:
                            break;
                    }
                } else {
                    op = null;
                }

                return left;
            }
            ///, initialLeft
        );
//        console.log(formula + " result:" + result)
        //console.log(" MultiSelect:" + currObj.MultiSelect)
        return result;
    }
};

const EvalAgg = (formula, currObj) => {
    let resolveObj;
    switch (GetFunName(formula)) {
        case "LEN:":
            //get objects
            formula = formula.replace(aggRegex, "");
            resolveObj = resolve(formula.trim(), currObj);

            if (Array.isArray(resolveObj)) {
                return resolveObj?.length;
            } else if (typeof resolveObj !== "object") {
                return resolveObj?.toString().length;
            }
            break;
        case "EMPTY:":
            formula = formula.replace(aggRegex, "");
          
            resolveObj = resolve(formula.trim(), currObj);
          
            return isEmpty(resolveObj);
            
        case "NOTEMPTY:":
            formula = formula.replace(aggRegex, "");
          
            resolveObj = resolve(formula.trim(), currObj);

            return !isEmpty(resolveObj);
            
        case "SUM:":
            break;
        case "AVG:":
            break;
        case "MIN:":
            break;
        case "MAX:":
            break;
        default:
            break;
    }
};

const GetFunName = (formula) => {
    let innerGroups = formula.match(aggRegex);
    return innerGroups && Array.isArray(innerGroups) && innerGroups.length > 0
        ? innerGroups[0]
        : undefined;
};

//const EvalRule = (rule, obj) => {
//    console.log(rule);
//};

const EvalMethod = (method, obj) => {
    console.log(method);
};

const add = (a, b) => {
    return a + b;
};
const subtract = (a, b) => {
    if (!isNumber(a)) throw new Error(a + " - Not a number");
    if (!isNumber(b)) throw new Error(b + " - Not a number");
    return a - b;
};
const multiply = (a, b) => {
    if (!isNumber(a)) throw new Error(a + " - Not a number");
    if (!isNumber(b)) throw new Error(b + " - Not a number");
    return a * b;
};
const divide = (a, b) => {
    if (!isNumber(a)) throw new Error(a + " - Not a number");
    if (!isNumber(b)) throw new Error(b + " - Not a number");
    if (b === 0) throw new Error(b + " - Divisible by 0");
    return a / b;
};

//const count = (a) => {
//    return a.length;
//};
const and = (a, b) => {
    return a && b;
};
const or = (a, b) => {
    return a || b;
};
//const not = (a) => {
//    return !a;
//};

const eq = (a, b) => {
    return a === b;
};
const neq = (a, b) => {
    return a !== b;
};
const less = (a, b) => {
    return a < b;
};
const lesseq = (a, b) => {
    return a <= b;
};

const gr = (a, b) => {
    return a > b;
};

const greq = (a, b) => {
    return a >= b;
};

const prepareNumberOperation = (a, b) => {
    if ((!isNumber(a) && !isNumber(b)) || (isNumber(a) && isNumber(b))) {
        return [a, b];
    }
    if (!isNumber(a) && isNumber(b)) {
        a = !a || a === "" ? 0 : a;
    }
    if (!isNumber(b) && isNumber(a)) {
        b = !b || b === "" ? 0 : b;
    }
    return [a, b];
};

const isAggFun = (a) => {
    let result = aggRegex.test(a);
    aggRegex.lastIndex = 0;
    return result;
};

const isEmpty = (obj) => {
    
        // null and undefined are "empty"
        if (obj == null || obj === undefined || obj === "") return true;

        if (!isNaN(obj)) return false;
        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0) return false;
        if (obj.length === 0) return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== "object") return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    
};

const isBoolean = (a) => {
    const boolRegex = /\b(true|false)\b/;
    let result = boolRegex.test(a);
    return result;
};
const isQuoted = (a) => {
    let result = litteralRegex.test(a);
    litteralRegex.lastIndex = 0;
    return result;
};
const isNumber = (a) => {
    return !isNaN(parseFloat(a)) && isFinite(a);
};

const pageStore = new Store("page")
const resolve = (path, currObj) => {
    if (path.indexOf("@comp.") === 0) {
        path = path.replace("@comp.","")
        const splitRegex = /\[|\]/;
        return path.split(".").reduce(function (prev, curr) {
            splitRegex.lastIndex = 0;
            if (splitRegex.test(curr)) {
                let currSplit = curr.split(splitRegex).filter((x) => x !== "");
                currSplit.forEach((elm) => {
                    prev = prev[elm];
                });
                return prev ? prev : null;
            }
            return prev ? prev[curr] : null;
        }, currObj);
    }
    return pageStore.Get(path);
};

const Predicates = {
    eq: (akey, bkey, a, b) => {
        let left = GetLeftOperands(akey, a);
        let right = GetRightOperands(bkey, b);
        return left?.toString() === right?.toString();
    },
    neq: (akey, bkey, a, b) => {
        let left = GetLeftOperands(akey, a);
        let right = GetRightOperands(bkey, b );
        return left?.toString() !== right?.toString();
    }
};

const GetLeftOperands = (key, obj) => {
    if (obj !== undefined) {
        return obj[key];
    }
    return key;
};

const GetRightOperands = (key,obj) => {
    
    if (obj !== undefined) {
        return resolve(key,obj);
    }
    return key;
};


const CreateFilterPredicate = (exp) => {
    const groupRegex = /\((?:[^()])*\)/g;
    const innerGroups = exp.match(groupRegex);
    //const filter = new Filter().chain()
    let filter = [];
    innerGroups?.length > 0 &&
        innerGroups.forEach((grp) => {
            exp = exp.replace(grp, "~");
            grp = grp.replace(/\(|\)/g, "");
            let f = CreateFilterPredicate(grp);
            filter = filter.concat(f);
        });

    const logopRegex = /(\sAND\s|\sOR\s)/gi;
    const conditions = exp.match(logopRegex);

    conditions?.length > 0 &&
        exp.split(logopRegex).forEach((grp) => {
            if (grp !== "~") {
                exp = exp.replace(grp, "~");
                filter.push(grp);
            }
        });

    if (conditions === null || conditions?.length === 0) {
        filter.push(exp);
        exp = "~";
    }
    exp = exp.replace(/~+/g, "~");

    //console.log(filter)
    if (exp !== "~") {
        let f = CreateFilterPredicate(exp);
        filter = filter.concat(f);
    }
    return filter;
};

export const resolveArrayObj = (exp,array,currObject) => {
    const filters = CreateFilterPredicate(exp);

    let filter = new Filter().chain();
    //let initial = filters[0];

    filters.reduce((prev, current) => {

        if (
            current.trim().toLowerCase() !== "and" &&
            current.trim().toLowerCase() !== "or"
        ) {

            let filterMethod = prev.trim().toLowerCase();
            if (
                prev.trim().toLowerCase() !== "and" &&
                prev.trim().toLowerCase() !== "or"
            ) {
                filterMethod = "or";
            }

            const opRegex = /(==|<=|>=|<|>|!=)/g;
            const op = current?.match(opRegex);
            const operands = current?.split(op);
            const left = operands[0].trim();
            const right = operands[1]?.trim();
            if (op !== null) {
                switch (op[0]) {
                    case "==":
                        if (filterMethod === "and") {
                            filter.and(Predicates.eq, left, right, currObject);
                        } else if (filterMethod === "or") {
                            filter.or(Predicates.eq, left, right, currObject);
                        }
                        break;
                    case "!=":
                        if (filterMethod === "and") {
                            filter.and(Predicates.neq, left, right, currObject);
                        } else if (filterMethod === "or") {
                            filter.and(Predicates.neq, left, right, currObject);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        prev = current;
        return prev;
    }, filters[0]);

    return filter.apply(array)
    //console.log(filter.execute(arr));
};



class Filter {
    constructor() {
        this.filters = [];
    }
    chain() {
        this.filters = [];
        return this;
    }
    or(predicate, lkey, rkey,cobj) {
        this.filters.push({ fn: predicate, l: lkey, r: rkey, op: "or", cobj: cobj });
        return this;
    }
    and(predicate, lkey, rkey, cobj) {
        this.filters.push({ fn: predicate, l: lkey, r: rkey, op: "and", cobj: cobj });
        return this;
    }
    apply(items) {
        return items.reduce(
            (results, item) =>
                this.__shouldKeepItem(item) ? results.concat(item) : results,
            []
        );
    }
    /** @private */
    __startCondition() {
        return this.filters.length ? (this.filters[0].op === "and" ? 1 : 0) : 0;
    }
    /** @private */
    __shouldKeepItem(item) {
        return this.filters.reduce((keep, filter) => {
            switch (filter.op) {
                case "or":
                    return keep || filter.fn(filter.l, filter.r, item, filter.cobj);
                case "and":
                    return keep && filter.fn(filter.l, filter.r, item, filter.cobj);
                default:
                    return keep;
            }
        }, this.__startCondition());
    }
}