import React, { memo } from "react";

import { Typography, Menu, MenuItem, Avatar } from "@mui/material";
import { AppBarButton } from "./AppBarButton";
//import { ProfileMenuConfig } from "../../Configs/ProfileMenuConfig";


/**
 * Renders a button which, when selected, will open a popup for logout
 */

 export const SignOutButton = memo((props, theme) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const name = props.name;
    const username = props.username;
    


    const stringAvatar = (name) => {
        const firstName = name?.split(' ')[0] ?? "";
        const lastName = name?.split(' ')[1] ?? "";
        return {
            children: `${firstName[0]}${lastName[0]}`,
        };
    }
    const LogoutConfig = [
        {
            id: 2,
            caption: "Logout",
            icon: "logout",
            click: () =>props.logout()
        }
    ]
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

   
     const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    return (
        <>
            <AppBarButton data-qa-element="avatarButton" onClick={handleOpenNavMenu} sx={{ display: 'flex', alignContent: 'flex-end' }}>
                <Avatar
                    {...stringAvatar(name)}
                    sx={{ bgcolor: 'primary', width: 45, height: 45 }}
                    src={props.avatarUrl}
                />
               
            </AppBarButton>

            <Menu data-qa-container="avatarMenu"
                id="menu-singoutbar"
                anchorEl={anchorElNav}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                keepMounted
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        width: 350,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                <MenuItem>
                    {name ? 
                        <>
                            <Typography variant="h6">{name}<Typography variant="body2"> {username}</Typography></Typography>
                        </>    
                        : ""}

                    </MenuItem>
                {props.ProfileMenuConfig?.map((page, index) => (
                    <MenuItem key={"l" + index} data-qa-element={page.caption} onClick={()=> {props.navigate(page.navUrl);handleCloseNavMenu();}} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/*<Icon>{page.icon}</Icon>*/}
                            <Typography textAlign="center">{page.caption}</Typography>
                        </MenuItem>
                    ))}
                {LogoutConfig.map((page, index) => (
                    <MenuItem key={"l" + index} data-qa-element={page.caption} onClick={page.click} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/*<Icon>{page.icon}</Icon>*/}
                            <Typography textAlign="center">{page.caption}</Typography>
                        </MenuItem>
                    ))}

            </Menu>
        </>

    );
})

