import React from "react";
import { Box, Toolbar } from '@mui/material';
import { Footer } from "./Footer/Footer"
import { AppNavBar } from "./AppBar/AppNavBar";

export const PageLayout = (props) => {
    const urlParams = new URLSearchParams(window.location.search);
    const app = urlParams.get("app");
    return (
        <> {app !== "salesforce" && <>
            <AppNavBar appName={props.appName} RouteConfig={props.RouteConfig} ProfileMenuConfig={props.ProfileMenuConfig} LoginResources={props.LoginResources}></AppNavBar>
            <Toolbar></Toolbar></>}
            <Box position="static" sx={{ mx: 'auto', flex: '1 0 auto', zIndex: 'tooltip'/*height: window.innerHeight - 100*/ }}>
                {props.children}
            </Box>
            {app !== "salesforce" && <Footer></Footer>}
        </>
    );
};