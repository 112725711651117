var PageStore = {}
var AppStore = {}
export class Store {
    constructor(type) {
        this.type = type
      
        if (this.type === "app") {
            this.Store = AppStore;
        }
        else {
            this.Store = PageStore;
        }
       
    }

    EnableLocalDB(location, cached) {
        this.cached = cached
        this.location = location
        if (this.cached === true) {
            this.db = new DB("portaldb", this.type);
            this.db.Open();
        }
    }

    async DeleteLocalDB() {
        await this.db.Delete("portaldb");
    }
    Get(key) {
        return key && (this.#resolve(key, this.Store) || undefined);
    }

    Set(key, value, storeDB) {
        // zip it
        this.Store[key] = value
        if (storeDB === true) {
            this.cached && this.db.Put(this.location + "_" + key, value);
        }
    }
    Clear(key) {
        //this.Store = ((k, { [k]: _, ...o }) => o)(key, this.Store)
        delete this.Store[key]
    }

    ClearAll() {
        Object.keys(this.Store).forEach(key => { delete this.Store[key] })
    }
    GetKeys() {
        return Object.keys(this.Store);
    }

    #resolve(path, obj) {
        return this.#resolveObj(path,obj)
    }

    
    #resolveObj(path, obj) {
        const splitRegex = /\[|\]/;
        return path.split(".").reduce(function (prev, curr) {
            splitRegex.lastIndex = 0;
            if (splitRegex.test(curr)) {
                let currSplit = curr.split(splitRegex).filter((x) => x !== "");
                currSplit.forEach((elm) => {
                    prev = prev[elm];
                });
                return prev ? prev : null;
            }
            return prev ? prev[curr] : null;
        }, obj);
    };

    async LoadFromLocalDB() {
        let items = await this.db?.GetMany(this.location);
        for (let i in items) {
            if (items[i].value) {
                const K = items[i].page.replace(this.location + "_", "");
                this.Set(K, items[i].value);
                this.Store["createdon_" + K] = items[i].createdon
               // this.Set(items[i].page.replace(this.location + "_", "createdon_"), items[i].createdon);
            }
        }
    }
}

class DB {
    
    constructor(dbname, store) {
        this.dbName = dbname
        this.store = store
        this.db = null;
    }
    Open() {
        let self = this;
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(self.dbName, 1)
            request.onsuccess = (event) => {
                self.db = request.result;
                resolve();
            }
            request.onerror = (e) => {
                // Returns error event
                reject(e);
            }
            request.onupgradeneeded = (event) => {
                self.db = event.target.result;

                // Create an objectStore to hold information about our customers. We're
                // going to use "ssn" as our key path because it's guaranteed to be
                // unique - or at least that's what I was told during the kickoff meeting.
                const objectStore = self.db.createObjectStore(self.store, { keyPath: self.store });

                // Use transaction oncomplete to make sure the objectStore creation is
                // finished before adding data into it.
                objectStore.transaction.oncomplete = (event) => {
                    resolve();
                };

            }
        });

    }
    async Get(key) {
        let self = this;
        if (self.db === null) {
            await self.Open();
        }
        return new Promise((resolve, reject) => {
            const objectStore = self.db.transaction([self.store], "readwrite").objectStore(self.store);
            const request = objectStore.get(key);
            request.onerror = (event) => {
                // Handle errors!
                reject();
            };
            request.onsuccess = (event) => {
                // Do something with the request.result!
                //console.log(request.result);
                resolve(request.result);
            };
        });
    }
    async GetMany(query) {
        let self = this;
        if (self.db === null) {
            await self.Open();
        }
        return new Promise((resolve, reject) => {
            
            const objectStore = self.db.transaction([self.store], "readwrite").objectStore(self.store);
            let range = IDBKeyRange.bound(query, query + '\uffff');
            const request = objectStore.getAll(range);
            request.onerror = (event) => {
                // Handle errors!
                reject();
            };
            request.onsuccess = (event) => {
                // Do something with the request.result!
                // console.log(request.result);
                resolve(request.result);
            };
        });
    }
    async Put(key, value) {
        let self = this;
        if (self.db === null) {
            await self.Open();
        }
        await self.remove(key);
        return new Promise((resolve, reject) => {
            const transaction = self.db.transaction([self.store], "readwrite")
            transaction.oncomplete = (event) => {
                resolve();
            };

            transaction.onerror = (event) => {
                // Don't forget to handle errors!
                reject();
            };

            const objectStore = transaction.objectStore(self.store);
            let date = new Date();
            const result = objectStore.put({ [self.store]: key, value: value, createdon: date.getTime() });
            result.onsuccess = (event) => {
                resolve();
            };
        });
    }
    async remove(key) {
        let self = this;
        if (self.db === null) {
            await self.Open();
        }
        return new Promise((resolve, reject) => {
            const transaction = self.db.transaction([self.store], "readwrite")
            const objectStore = transaction.objectStore(self.store);
            const request = objectStore.delete(key);
            transaction.oncomplete = () => {
                // Do something with the request.result!
                //console.log("cahce deleted");
                resolve(request.result)
            };
        });
    }
    async Delete(databaseName) {
        let self = this;
        console.log("Closing indexedDB: " + databaseName);
        /*
           indexedDB.deleteDatabase returns a promise.
           If the database is in use (i.e. still open), the callback is expected to close it.
           An error will be thrown if a callback is not specified.
        */
        return await indexedDB.deleteDatabase(databaseName, () => {
            self.db.close(); // database is deleted shortly after this call
        });
    }

}
