import React, { useState, useEffect, useRef } from 'react';
import StateStore from "../StateStore";
import {
	 Snackbar, SnackbarContent
} from '@mui/material';


export const ProgressRefresh = (props) => {
	const stateStore = new StateStore("Error");
	const [, setDisplay] = useState();
	const ref = useRef([]);
	const UpdateComponent = (stateValue) => {
		setTimeout(() => {
			let forComp = stateValue.for;
			let disp = stateValue.display;
			let displayTemp = [...ref.current];
			if (disp === true) {
				//add to stack
				if (!displayTemp.includes(forComp)) {
					displayTemp.push(forComp);
				}
				if (displayTemp.length === 1) {
					setDisplay([...displayTemp]);
				}
			}
			else {
				//remove from stack
				if (forComp === "pageclear") {
					displayTemp = []
					setDisplay([...displayTemp]);
				}
				else {


					if (displayTemp.includes(forComp)) {
						displayTemp.splice(displayTemp.indexOf(forComp), 1);
					}
					if (displayTemp.length === 0) {
						setDisplay([...displayTemp]);
					}
				}

			}
			ref.current = [...displayTemp]
		}, 0);
	};

	useEffect(() => {
		stateStore.Subscribe("refresh", "refresh", UpdateComponent);

		return () => {
			stateStore.UnSubscribe("refresh", "refresh");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (ref.current.length === 0)
		return <></>;
	return (
		<Snackbar
			open={true}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			sx={{ bottom: '2px !important'} }
			key={Math.random()}
		>
			<SnackbarContent
				sx={{
					backgroundColor: 'primary.main'}}
				message="Refreshing data..."
			/>
		</Snackbar>
	)

}